@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;


.varela-round-regular {
    font-family: "Varela Round", system-ui;
    font-weight: 500;
    font-style: normal;
  }
  
  .lexend-bold {
    font-family: "Lexend", system-ui;
    font-weight: 400;
    font-style: normal;
  }
  
  .lexend-light {
    font-family: "Lexend", system-ui;
    font-weight: 300;
    font-style: normal;
  }