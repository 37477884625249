@import '../../YoutubeEmbed/mixins';
@import '../../YoutubeEmbed/variable';

.post {
  background-color: #eeeeee;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 5px 7px -7px $box-shadow;

  @include for-xl-devices {
    width: 90%;
  }

  @include for-large-devices {
    width: 85%;
  }



  .top {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.3rem;
    position: relative;
    .delete-button {
      position: absolute;
      right: 10px;
      color: red;
    }
    .info {
      margin-left: 0.6rem;

      h4 {
        text-transform: capitalize;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      span {
        font-size: small;
        color: $comment-text;
      }
    }
  }

  .texxt {
    display: flex;
    padding: 0.3rem 0;
    flex-wrap: wrap;
    margin: 0.6rem 0.3rem;
    word-break: break-all;
    word-wrap: break-word;
  }

  .reactions-count:hover {
    text-decoration: underline;
    cursor: pointer;
}

  .image {
    img {
      width: 100%;
    }
  }
  .video {
    position: relative;
    max-width: 100%;
    overflow: hidden;
  }
  
  .video video {
    width: 100%;
    height: auto;
  }
  
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  
  .play-icon {
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    transition: background-color 0.3s;
  }
  
  .play-icon:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .play-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease-in-out;
}

.play-button.playing {
  display: none; /* Hide play button when video is playing */
}

.video:hover .play-button {
  opacity: 1; /* Show play button on hover */
}
  
}

.bottomAction {
  padding: 0.3rem 0.3rem 0;
  border-top: 1px solid $border-color;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .action {
    transition: linear 200ms;
    cursor: pointer;
    display: flex;
    gap: 5px;
    flex-direction: row;
    padding: 0.5rem 1rem ;
    flex: 1;
    align-items: center;
    justify-content: center;
     
    &:hover {
      background-color: $button-hover;
      transform: translateY(-2px);
      border-radius: 0.3rem;
    }

    h4 {
      color: $comment-text;
      margin-left: 0.5rem;

      @include for-small-screens {
        display: none;
      }
    }
    
    .postAction {
      margin: 0 0.5rem;
      color: $icon;
    }
    .blue{
      color: blue;
    }
    .green{
      color:green;
    }
    .grey{
      color: white
    }
  }
}
.post-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.post-overlay-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}