.topBar {
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15); */
  /* height: 100%; */
  display: flex;
  align-items: center;
  background-color: #eeeeee; 
  color: white;
  width: 94%;
  justify-content: space-between;
  /* padding-right: 30px; */
  /* margin-right: 130px; */
}
.top-bar {
  background-color: #eeeeee;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0px;
  z-index: 3;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 2%;
}

/* .search {

} */

.search input {
  width: 80%;
  border-radius: 6px;
  color: green !important;
  background: #eeeeee;
  padding-left: 15px;
}

.search input::placeholder {
  color: #004C8A;
}

.top {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

}
.top button {
  background-color: #eeeeee;
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 8px;
  padding: 3px 10px;
  font-size: 14px;
  cursor: pointer;
}

.profile-list{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  /* width: 33%; */
}
.profile-menu {
  position: relative;
}

.profile-options {
  position: absolute;
  top: 50px; /* Adjust this value as needed */
  right: 0;
  background-color: #ffffff;
  list-style: none;
  padding: 0;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  color: black;
  width: 13vw;
}

/* Responsive for medium screens */
@media (max-width: 1024px) {
  .profile-options {
    width: 20vw;
  }
}

/* Responsive for small screens */
@media (max-width: 768px) {
  .profile-options {
    width: 30vw;
  }
}

/* Responsive for extra small screens */
@media (max-width: 480px) {
  .profile-options {
    width: 50vw;
  }
}


.profile-options li {
  padding: 10px;
  cursor: pointer;
}

.profile-options li:hover {
  background-color: #174873;
  color: white;
}

.notifications-card {
  position: absolute;
  right: 0;
  background-color: #004C8A;
  list-style: none;
  padding: 0;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  color: whitesmoke;
  width: 25vw;
  min-height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Responsive for smaller screens */
@media (max-width: 1024px) {
  .notifications-card {
    width: 35vw;
    min-height: 25vh;
  }
}

@media (max-width: 768px) {
  .notifications-card {
    width: 45vw;
    min-height: 30vh;
  }
}

@media (max-width: 480px) {
  .notifications-card {
    width: 80vw;
    min-height: 35vh;
  }
}


.messages-card {
  position: absolute;
  top: 50px; /* Adjust this value as needed */
  right: 0;
  background-color: #ffffff;
  list-style: none;
  padding: 0;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  color: black;
  width: 13vw;
  min-height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
