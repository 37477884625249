.ico{
    display: flex;
    flex-direction: row;
}
.ico .icons1{
    width: 70%;
}
.ico .icons1 .ul{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    gap:2em;
   background-color:  #fff;
    padding: 2px 5px;
    margin: 20px 10px 20px 0px;
    border-radius: 10px;
    font-size: 20px;
    box-shadow: 0px 5px 7px -7px #8D949E;
    height: 5vh;
}
.ico .list{
    width: 30%;
    display: flex;
    flex-direction:column;
    
}
.ico .list ul{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
    margin: 0px;
    padding: 0px;
    font-size: 14px;
}
.ico .list .list1{
    background-color: #fff;
    padding: 10px 10px;
    margin: 20px 0px 10px 10px;
    border-radius: 10px;
    box-shadow: 0px 5px 7px -7px #8D949E;
    height: 20vh;
}
.ico .list .left{
    background-color: #fff;
    padding: 10px 10px;
    margin: 10px 0px 10px 10px;
    border-radius: 10px;
    box-shadow: 0px 5px 7px -7px #8D949E;
    height: 11vh;
    width: 100%;
}
.ico .list .alb{
    background-color: #fff;
    padding: 10px 10px;
    margin: 10px 0px 10px 10px;
    border-radius: 10px;
    box-shadow: 0px 5px 7px -7px #8D949E;
    height: 5vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    font-size: 18px
}
.ico .list .fol{
    background-color: #fff;
    padding: 10px 10px;
    margin: 10px 0px 10px 10px;
    border-radius: 10px;
    box-shadow: 0px 5px 7px -7px #8D949E;
    height: 5vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    font-size: 18px
}
.ico .list .fo{
    background-color: #fff;
    padding: 10px 10px;
    margin: 10px 0px 10px 10px;
    border-radius: 10px;
    box-shadow: 0px 5px 7px -7px #8D949E;
    height: 5vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    font-size: 18px
}