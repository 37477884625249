.comment-list {
  list-style-type: none;
  padding-left: 0;
}

.comment-list ul {
  list-style-type: none;
  margin-left: 20px; /* Indentation for nested comments */
  padding-left: 20px; /* Additional padding to align with the parent comment */
  border-left: 2px solid #F8A700; /* Thread connector */
  position: relative;
}

.comment-list ul::before {
  content: '';
  position: absolute;
  top: 0;
  left: -10px;
  height: 100%;
  /* border-left: 2px solid #F8A700; */
}

.comment {
  position: relative;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

/* .comment img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
} */

.comment p {
  margin: 0px;
  padding: 10px;
  border-radius: 10px;
}

.comment-menu {
  position: relative;
}

.comment-buttons {
  display: flex;
  flex-direction: row-reverse;
  gap: 1em;
  border-radius: 25%;
}

.comment-buttons button {
  
  font-size: 18px;
  background-color: white;
  font-family: sans-serif;
  margin: 10px 0px;
  border: none
}
.comment-buttons button:hover {
  
  text-decoration: underline;
  
  
}

.reply-form textarea:hover {
  border: none;
}

.comment-input:focus {
  border: none;
  border-bottom: 2px solid #F8A700;
  outline: none;
}
