.don-spon-title{
    background-color: #174873;
    width: 100%;
    height: 17vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    gap: 10px;
    font-size: 2.5rem;
}