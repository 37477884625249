.request{
    width: 70%;
    font-size: 20px;
    font-weight: 500;
    padding: 10px;
}

.accept{
    width: 15%;
    padding: 10px;

}
.reject{
    width: 15%;
    padding: 10px;

}