.ple img{
    width: 7vw;
    height: 15vh; 
    border-radius: 50%;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 0px;
    display: flex;
    flex-direction: row;
}
.ple button{
    border-radius: 10px;
    padding: 5px 15px ;
    gap: 0.5em;
}

.message-follow button{
    background-color: #004C8A;
    padding: 8px 32px;
    border-radius: 32px;
    color: #F8F8FF;
    font-weight: 500;
    border: none;
    font-size: 20px;
    font-family: 'Inter';
}