.ids-upper{
    display: flex;
    flex-direction: row;
    height: 75%;
}
.ids-details{
    width: 60%;
    display: flex;
    flex-direction: column-reverse;
    border-radius: 12px;
}

.ids-amount{
    width: 40%;
    background-color: #fff8f8;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
}

.ids-user-details{
    color: black;
    background-color: #f3cdcd;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.ids-user-details img{

        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        display: inline;
      
}

.ids-user-details p {
    display: inline;
    margin-left: 10px;
  }

.ids-bar{
    width: 95%;
    background-color: #beaeae;
    border-radius: 12px;
    
}
.ids-fill-bar{
    border-radius: inherit;
    height: 10px;
}

.ids-lower{
    border-radius: 8px;
    background-color: white;
    margin-top: 20px;
}
.ids-info{
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 15px;
    padding-top: 10px;
}

.ids-amount-button{
    background-color: rgb(0 0 0 / 15%);
    color: #000000;
    letter-spacing: 0.5px;
    border: 0;
    font-size: 15px;
    padding: 0px 16px;
    line-height: 45px;
    margin-right: 10px;
    border: none;
    border-radius: 12px;
    width: 96%;
}
.share-options{
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 12px;
}

.share-options button{
    border: none;
    background-color: inherit;
}