@import '../../YoutubeEmbed/variable';
@import '../../YoutubeEmbed/mixins';

.feed {
  display: flex;
  flex-direction: column;
  flex: 0.45;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  @include for-xl-devices {
    flex: 0.3;
  }

  @include for-large-devices {
    flex: 1;
    // align-items: center;
    // justify-content: center;
  }
  
  @include for-small-screens {
    overflow-x: hidden;
  }
}

.post-box {
  background-color: #eeeeee;
  border: 1px solid #dddfe2;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  // padding: 10px;
  width: 100%;
  transition: box-shadow 0.3s;
  display: flex;
  flex-direction: column;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .info {
      margin-left: 10px;

      h4 {
        margin: 0;
        font-size: 20px;
        color: #3A3A3A;
        font-weight: 600;        
      }

      span {
        font-size: 12px;
        color: #65676b;
      }
    }
  }

  .texxt {
    margin: 15px 0;

    p {
      font-size: 16px;
      line-height: 1.4;
      margin: 0;
      font-weight: 500;
      color: #3A3A3A;
      font-family: 'Inter';
    }
  }

  .image {
    height: 300px; 
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .bottomAction {
    display: flex;
    align-items: center;
    margin-top: 15px;

    .action {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 20px;
      color: #65676b;

      .postAction {
        font-size: 18px;
        margin-right: 5px;

        &.blue {
          color: #1877f2;
        }
      }

      h4 {
        font-size: 20px;
        margin: 0;
        color: #3A3A3A;
        font-family: 'Inter';
      }
    }
  }
}

.infiniteScroll::-webkit-scrollbar {
  display: none;
}

.infiniteScroll {
  scrollbar-width: none;  
  -ms-overflow-style: none;  
}

.poll-box-container{
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    background-color: #eeeeee;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}