.display-post-container{
    /* background-color: aquamarine; */
    margin-top: 20px;
    /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); */
    border-radius: max(0px, min(8px, calc((100vw - 4px - 100%) * 9999))) / 8px;
    display: flex;
    flex-direction: row;
    gap: 3vw;
    width: 100%;
    flex-wrap: wrap;
}

.display-post-card{
    width: 30%;
    background-color: white;
    /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); */
    border-radius: max(0px, min(8px, calc((100vw - 4px - 100%) * 9999))) / 8px;
    position: relative;
    height: 50vh;
}

.display-post-image{
    overflow: hidden;
    width: 100%;
    border-radius: inherit;
    height: 80%;  

}
.display-post-image img{
   width: 100%;  
   height: 100%;
}

.display-post-title{
    padding: 0px 12px;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
}

.display-post-edit{    
    margin-top: 18px;
    border-radius: 8px;
    width: 100%;
}

.display-post-edit button{
    background: #ebeef0;
    border: 0;
    border-radius: 8px;
    width: 100%;
}

.display-post-noGroups{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .display-post-editt{
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 8px;
    border-radius: 10px;
}

.display-post-editt button{
    background: #ebeef0;
    border: 0;
    border-radius: 8px;
    width: 100%;
} */
