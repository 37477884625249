.selected-user{
    background-color: blueviolet;
}
.myChat{
    background-color: aqua;
    color: black;
    display: inline-block;
}
.yourChat{
    background-color: white;
    color: grey;
    display: inline-block;
}