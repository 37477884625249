.member{
    background-color: #174873;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30vh;
    justify-content: center;
}
.member h1{
    color: aliceblue;
    gap: 0.5em;
    display: flex;
   
}
.member form input{
    border-radius: 10px;
    box-shadow: rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
    border-radius: max(0px, min(8px, calc((100vw - 4px - 100%) * 9999))) / 8px;
    transition: background-color 240ms, box-shadow 240ms;
    color: #393d4a;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    padding: 8px;
    width: 24vw;
    border: 0;
    outline: 0;

}       

.pro{
    display: flex;
    gap: 17px;
}
.select-dropdown {
    background-color: 
    #eeeeee; 
    padding: 8px;
    /* border: 1px solid #00796b;  */
    position: relative;
    font-size: 16px; /* increase font size */
  }
  
  .select-dropdown::after {
    font-size: 28px; 
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }
        
      