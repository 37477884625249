.PageTitle-header{
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: max(0px, min(8px, calc((100vw - 4px - 100%) * 9999))) / 8px;
    padding: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    font-weight: normal;
    line-height: 26px;
}