.top {
  display: flex;
  align-items: center;
}

.info {
  margin-left: 10px;
}

.delete-button {
  margin-left: auto;
}

.percentage-bar-container {
  margin: 10px 0;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border 0.3s ease, box-shadow 0.3s ease;
}

.percentage-bar-container:hover {
  border: 1px solid rgb(19, 97, 117);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.percentage-bar {
  height: 20px;
  background-color: rgb(19, 97, 117);
  border-radius: 5px;
  color: white;
  text-align: center;
  line-height: 20px;
}

.poll-modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #eeeeee;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 20px;
  width: 80%;
  max-height: 80vh;
  overflow-y: auto;
}

.poll-modal-box h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #3A3A3A;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
}

.voters-container {
  display: flex;
  justify-content: space-between;
}

.option-result {
  flex: 1;
  margin: 0 10px;  
  color: #3A3A3A;
  font-family: 'Inter';
}

.option-result h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #3A3A3A;
  font-weight: 500;
  font-family: 'Inter';
}

.voter-info {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.voter-info span {
  margin-left: 10px;
  font-size: 16px;
  color: #3A3A3A;
  font-family: 'Inter';
}

.option {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.option.clickable:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.option.voted {
  background-color: #e0e0e0;
  cursor: default;
}

.see-poll-results {
  font-weight: bold;
  cursor: pointer;
}

.see-poll-results:hover {
  text-decoration: underline;
}
