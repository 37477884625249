//main theme
$primary: #2d88ff;
$primary-light: #5098f7;
// $primary-light: #7995cd;
$secondary: #a6b8de;
$secondary-light: #c8d3e4;

$white: #fff;
$black: #000;

//background - light
$app-background: #f1f2f5;
$component-background: #fff;
$comment-background: #f0f2f5;
$comment-text: #65676b;
$icon: #4A4A4A;
$box-shadow: #8D949E;
$button-hover: #EBEDF0;
$button-background: #E4E6EB;
$button-icon: #000;
$sec-button-hover: #DADDE1;
$border-color: #eff2f5;