.ig-container{
    border-radius: 0px 0px 12px 12px;
}
.ig-upper-container{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    border-radius: inherit;
}

.ig-lower-container{
    margin-top: 15px;
    display: flex;
    gap: 1vw;
}

.ig-lc-card{
    background-color: white;
    border-radius: 8px;
}