/* styles.css */

  .styled-table {
    width: 100%;
    border-collapse: collapse; /* Ensure no gaps between data rows */
  }
  
  .styled-table thead {
    background-color: #eeeeee;
  }
  
  .styled-table thead th {
    padding: 12px 70px;
    position: relative;
    color: #3A3A3A;
    background-color: 
    #eeeeee;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    font-family: 'Inter';
  }
  
  .styled-table thead th:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 0;
    right: -5px;
    width: 10px;
    height: 100%;
    background-color: white; /* Match the header row background */
  }
  
  .styled-table tbody tr {
    border-bottom: 1px solid #F8A700; /* Add bottom border to each row */
  }
  
  .styled-table tbody td {
    padding: 40px 15px;
    vertical-align: top;
    border-bottom: none; /* Remove border from cells */
    text-align: center;
  }
  
  .styled-table tbody td:nth-of-type(1) {
    font-weight: bold;
  }
  