/* SocialMediaPost.css */
.social-media-post {
    width: 100%;
    transition: background-color 0.3s;
  }
  
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
  }

  .overlay.expanded {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  
  .card {
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    transition: height 0.3s;
    width: 100%;
  }

  .card.expanded {
    width: 100%;
  }
  
  /* .card-header {
    
  } */
  
  .card-header input {
    width: 100%;
    padding: 10px;
    border: none;
    outline: none;
  }
  
  .expanded-options {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 15vh;
  }
  
  .expanded-options button {
    padding: 5px;
    margin: 5px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .img-job-vid{
    display: flex;
    justify-content: space-evenly;
    height: 59%;
  }

  .img-job-vide{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    /* padding-left: 90px; */
    gap: 1vw;
  }


  .img-job-vide .expanded{
    display: none;
  }

  .img-job-vide button{
    border-radius: 2em;
    background-color: rgb(243, 243, 243);
    color: black;
    display: flex;
    align-items: center;
    gap: 0.3em;
    justify-content: center;
    border: none;
  }

  

  .img-job-vid button{
    min-width: 10vw;
    border-radius: 2em;
    background-color: rgb(243, 243, 243);
    color: black;
    display: flex;
    align-items: center;
    gap: 0.3em;
    justify-content: center;
  }

  


  .poll-gif-voice-feel{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    display: none;
  }

  .poll-gif-voice-feel button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: black;
    
  }
  .poll-gif-voice-feel button div{
    background-color: #f3f3f3;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    /* text-align: center; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .file-loc-audio{
    display: flex;
    display: none;
    justify-content: space-evenly;
  }

  .file-loc-audio button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: black;
  }
  .file-loc-audio button div{
    background-color: #f3f3f3;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    /* text-align: center; */
    display: flex;
    align-items: center;
    justify-content: center;
  }