/* PollModal.css */
.modal-overlay-poll {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content-poll {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    max-width: 90%;
  }
  
  .modal-content-poll h2 {
    margin-top: 0;
  }
  
  .modal-content-poll label {
    display: block;
    margin-bottom: 5px;
  }
  
  .modal-content-poll input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .modal-content-poll button {
    display: block;
    margin: 5px 0;
    padding: 10px;
    width: 100%;
    border: none;
    border-radius: 3px;
    background-color: #F8A700;
    color: white;
    cursor: pointer;
  }
  
  .modal-content-poll button:hover {
    background-color: #58a382;
  }
  
  /* From Uiverse.io by guilhermeyohan */ 
.checkbox-apple {
  position: relative;
  /* width: 50px; */
  height: 25px;
  /* margin: 20px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-apple label {
  position: absolute;
  top: 0;
  left: 290px;
  width: 50px;
  height: 25px;
  border-radius: 50px;
  background: linear-gradient(to bottom, #b3b3b3, #e6e6e6);
  cursor: pointer;
  transition: all 0.3s ease;
}

.checkbox-apple label:after {
  content: '';
  position: absolute;
  top: 1px;
  left: 1px;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

.checkbox-apple input[type="checkbox"]:checked + label {
  background: linear-gradient(to bottom, #4cd964, #5de24e);
}

.checkbox-apple input[type="checkbox"]:checked + label:after {
  transform: translateX(25px);
}

.checkbox-apple label:hover {
  background: linear-gradient(to bottom, #b3b3b3, #e6e6e6);
}

.checkbox-apple label:hover:after {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.yep {
  position: absolute;
  top: 0;
  left: 140px;
  width: 50px;
  height: 25px;
}
