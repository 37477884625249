.icon{
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
     background-color: #fff;
    border-radius: 10px; 
    padding: 8px 0px;
    box-shadow: 0px 5px 7px -7px #8D949E;
}